<template>
  <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="tableController.search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item prop="pharmacistName">
        <el-input placeholder="药师姓名" v-model="searchController.formModel.pharmacistName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="phoneNumber">
        <el-input placeholder="药师电话" v-model="searchController.formModel.phoneNumber" clearable></el-input>
      </el-form-item>
      <el-form-item prop="disabled">
        <el-select placeholder="状态" v-model="searchController.formModel.disabled" clearable>
          <el-option label="有效" :value="0"></el-option>
          <el-option label="无效" :value="1"></el-option>
        </el-select>
      </el-form-item>
    </template>
    <template #controll>
      <el-button type="primary" @click="clickAdd">新建</el-button>
    </template>
  </SearchForm>
  <ProTable
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />

  <!-- 模态 -->
  <AddModal ref="addModalRef" @successAdd="tableController.search" @successEdit="tableController.refresh" />
  <QRCodeCommonModal ref="QRCodeCommonModalRef" />
</template>

<script>
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import SearchForm from '@/components/SearchForm'
import ProTable from '@/components/ProTable'
import AddModal from './AddModal.vue'
import FetchSelect from '@/components/FetchSelect'
import CustomLink from '@/components/customStyle/CustomLink'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import { actionConfirm } from '@/utils/confirm'
import request from '@/utils/request'
import './index.less'
import QRCodeCommonModal from '@/components/modal/QRCodeCommonModal'
import { getHostUrl } from '@/utils/utils'

export default {
  setup() {
    const addModalRef = ref(null)
    const QRCodeCommonModalRef = ref(null)
    // searchForm
    const searchController = useSearchFormCtrller({
      form: {
        pharmacistName: '',
        phoneNumber: '',
        disabled: '',
      },
    })
    // table
    const columns = [
      { label: '药师姓名', prop: 'pharmacistName', width: 110 },
      { label: '登录账号', prop: 'loginName', width: 130 },
      { label: '手机号', prop: 'phoneNumber', width: 130 },
      { label: '药师年龄', prop: 'pharmacistAge', width: 90 },
      { label: '身份证号', prop: 'pharmacistPsptNo', width: 170 },
      { label: '创建时间', prop: 'createDateStr' },
      { label: '状态', prop: 'disabledName', width: 90 },
      {
        label: '签名',
        prop: 'signImg',
        width: 230,
        render: (v, record) => {
          return (
            (v && (
              <a className="pharmacistMng_qianming_a" href={v} target="_blank">
                {v}
              </a>
            )) || (
              <el-button
                link={true}
                onClick={() => {
                  const text = `https://dr.bld365.com/yaoshih5/index.html#/h5/pages/login/index?phoneNumber=${record.phoneNumber}`

                  QRCodeCommonModalRef.value.open({
                    text,
                    saveName: '药师_' + record.pharmacistName + '_二维码',
                    isShowText: true,
                  })
                }}
              >
                去签名
              </el-button>
            )
          )
        },
      },
      {
        label: '操作',
        prop: 'actions',
        width: 100,
        render: (_, record) => {
          const statusText = record.disabled == '0' ? '失效' : '生效'
          return (
            <div>
              <CustomLink
                onClick={() => {
                  clickEdit(record)
                }}
              >
                编辑
              </CustomLink>
              <CustomLink
                onClick={() => {
                  handleToggleStatus(record)
                }}
              >
                {statusText}
              </CustomLink>
            </div>
          )
        },
      },
    ]
    const tableController = useTableController({
      columns,
      ajax: ({ page, pageSize }) => {
        const postData = {
          page,
          rows: pageSize,
          ...searchController.formModel,
        }
        return request({
          url: '/web/system/pharmacist/getPharmacistList',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
    })

    function clickAdd() {
      addModalRef.value?.open()
    }

    function clickEdit(record) {
      addModalRef.value?.open(record)
    }

    function handleToggleStatus(record) {
      const statusText = record.disabled == '0' ? '失效' : '生效'
      actionConfirm(`确认${statusText}？`, () => {
        return request({
          url: '/web/system/pharmacist/updatePharmacistDisabled',
          data: {
            pharmacistCode: record.hospitalCode,
            disabled: record.disabled == '0' ? '1' : '0',
          },
        }).then(() => {
          tableController.refresh()
          ElMessage.success('操作成功')
        })
      })
    }

    return {
      searchController,
      tableController,
      //
      addModalRef,
      QRCodeCommonModalRef,
      clickAdd,
    }
  },
  components: { SearchForm, ProTable, FetchSelect, AddModal, QRCodeCommonModal },
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
