<template>
  <StyledModal :visible="visible" :title="`${isAdd ? '新建' : '编辑'}药师`" @close="close" @submit="submit" :submitLoading="submitLoading" width="700px">
    <el-form class="form_box" ref="formRef" :model="formModel.form" label-width="150px">
      <el-form-item label="药师姓名" prop="pharmacistName" :rules="[{ required: true, message: '请输入药师姓名' }]">
        <el-input v-model="formModel.form.pharmacistName" placeholder="请输入药师姓名"></el-input>
      </el-form-item>
      <el-form-item label="登录账号" prop="loginName" :rules="[{ required: true, message: '请输入登录账号' }]">
        <el-input v-model="formModel.form.loginName" placeholder="请输入登录账号" maxlength="18" :disabled="!isAdd"></el-input>
      </el-form-item>
      <el-form-item
        label="手机号"
        prop="phoneNumber"
        :rules="[
          { required: true, message: '请输入手机号' },
          { pattern: patternCreator.mobilePhone.pattern, message: '请输入正确格式的手机号' },
        ]"
      >
        <el-input v-model="formModel.form.phoneNumber" placeholder="请输入手机号" :disabled="!isAdd"></el-input>
      </el-form-item>
      <el-form-item
        label="身份证号"
        prop="pharmacistPsptNo"
        :rules="[
          { required: true, message: '请输入身份证号' },
          { pattern: patternCreator.identityNo.pattern, message: '请输入正确格式的身份证号' },
        ]"
      >
        <el-input v-model="formModel.form.pharmacistPsptNo" placeholder="请输入身份证号"></el-input>
      </el-form-item>
      <el-form-item label="职业药师证" prop="pharmacistCertImg" :rules="[{ required: true, message: '请上传职业药师证' }]">
        <ImgUpload v-model="formModel.form.pharmacistCertImg" :limit="1" />
      </el-form-item>
      <el-form-item label="职业药师注册证" prop="pharmacistRegisterCertImg" :rules="[{ required: true, message: '请上传职业药师注册证' }]">
        <ImgUpload v-model="formModel.form.pharmacistRegisterCertImg" :limit="1" />
      </el-form-item>
    </el-form>
  </StyledModal>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { cloneDeep } from 'lodash'
import StyledModal from '@/components/modal/StyledModal'
import FetchSelect from '@/components/FetchSelect'
import ImgUpload from '@/components/ImgUpload'
import { validateFormRef } from '@/common/utils_element'
import request from '@/utils/request'
import patternCreator from '@/utils/patternCreator'

const defaultForm = {
  pharmacistName: '',
  loginName: '',
  phoneNumber: '',
  pharmacistPsptNo: '',
  pharmacistCertImg: '', //       职业药师证(非空) ##2022-08-10
  pharmacistRegisterCertImg: '', // 职业药师注册证(非空) ##2022-08-10
  remark: '', //
}

export default {
  data() {
    return { patternCreator }
  },
  emits: ['successAdd', 'successEdit'],
  setup(_, ctx) {
    const visible = ref(false)
    const submitLoading = ref(false)

    const formRef = ref(null)
    const formModel = reactive({
      form: cloneDeep(defaultForm),
    })

    const record = reactive({ v: null })
    const isAdd = computed(() => (record.v ? false : true))

    function open(recordParam) {
      visible.value = true
      if (recordParam) {
        record.v = cloneDeep(recordParam)
        renderForm(cloneDeep(recordParam))
      }
    }

    function close() {
      visible.value = false
      record.v = null
      formModel.form = cloneDeep(defaultForm)
    }

    // form回显
    function renderForm(data) {
      formModel.form = {
        ...data,
      }
    }

    // 提交
    async function submit() {
      await validateFormRef(formRef)
      submitLoading.value = true
      request({
        url: isAdd.value ? '/web/system/pharmacist/createPharmacist' : '/web/system/pharmacist/updatePharmacist',
        data: {
          ...formModel.form,
          ...(isAdd.value ? {} : { pharmacistCode: record.v.pharmacistCode }),
        },
      })
        .finally(() => {
          submitLoading.value = false
        })
        .then(() => {
          ctx.emit(isAdd.value ? 'successAdd' : 'successEdit')
          close()
        })
    }

    return {
      visible,
      isAdd,
      submitLoading,
      open,
      close,
      submit,
      //
      formRef,
      formModel,
    }
  },
  components: { StyledModal, FetchSelect, ImgUpload },
}
</script>

<style lang="less" scoped>
.form_box {
  width: 90%;
}
</style>
